import React from "react"
import { injectIntl } from "gatsby-plugin-intl"

import menutabsStyles from "../styles/menutabs.module.scss"
import Menutab from "./menutab"

const Menutabs = ({ intl }) => (
  <div className={menutabsStyles.menutabs}>

    <div className={menutabsStyles.tpnetTabsWrapper}>
        
      <Menutab name={intl.formatMessage({ id: "LANDING.FEATURES" })} url="features"/>

      <Menutab name={intl.formatMessage({ id: "LANDING.PRICING" })} url="pricing"/>

      <Menutab name={intl.formatMessage({ id: "LANDING.SUPPORT" })} url="support"/>
{/* 
      <Menutab overflow-tab="" hidden="">
        <a
          href="#"
          className="tpnet-icon tpnet-icon-arrow-drop-down"
        >
          More
        </a>
        <div className="tpnet-tabs-overflow-menu"></div>
      </Menutab> */}
    </div>
  </div>
)

export default injectIntl(Menutabs)
