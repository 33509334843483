import React from "react"
import { IntlContextConsumer, changeLocale, onChange } from "gatsby-plugin-intl"

const languageName = {
  en: "English",
  tr: "Türkçe",
}

export default class Language extends React.Component {
  state = {
    selectedId: "en",
  }

  handleChange = event => {
    this.setState({ selectedId: event.target.value })
    changeLocale(event.target.value)
  }

  render() {
    return (
      <div style={{textAlign: `end`}}>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map(language => (
            <a
              key={language}
              onClick={() => changeLocale(language)}
              style={{
                color: currentLocale === language ? `#039be5` : `gray`,
                margin: 10,
                cursor: `pointer`,
              }}
            >
              {language}
              {/* {languageName[language]} */}
            </a>
          ))
        }
      </IntlContextConsumer>
    </div>
    )
  }
}
