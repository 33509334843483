import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import Header from "../components/header"
import Footer from "../components/footer"
import SideBar from "../components/sidebar";
import "../styles/layout.scss"

const Layout = ({ children, intl }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <SideBar />

      <Header siteTitle={intl.formatMessage({ id: "LANDING.TITLE" })} />

      <main>{children}</main>

      <Footer/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default injectIntl(Layout)
