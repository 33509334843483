import React from "react"
import { Link } from "gatsby-plugin-intl"
import menutabStyles from "../styles/menutab.module.scss"

const Menutab = ({ name, url }) => (
  <div className={menutabStyles.menutab}>
    <Link
      to={`/${url}`}
      className="gc-analytics-event"
      data-label={`Tab: ${name}`}
    >
      {name}
    </Link>
  </div>
)

export default Menutab
