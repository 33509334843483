import React from "react"
import {
  FormattedMessage,
  injectIntl,
  navigate,
  Link,
} from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import "../styles/header.scss"
import Menutabs from "./menutabs"
import TalepnetLogo from "../images/logos/talepnet-logo.png"

const Header = ({ siteTitle }) => (
  <header className="tpnet-header">
    <div className="header-wrapper-wrapper">
      <div className="header-wrapper">
        <div className="header-row">
          <div className="logo-wrapper">
            <a
              href="#"
              onClick={e => {
                e.preventDefault()
                navigate("/")
              }}
              className="logo-link gc-analytics-event"
              data-label="Site logo"
            >
              <img src={TalepnetLogo} alt="TalepNET" className="logo" />
            </a>
          </div>

          <div className="row-middle">
            <div className="header-upper-tabs">
              <Menutabs></Menutabs>
            </div>
          </div>

{/*           <a
            className="header-link tpnet-top-button button gc-analytics-event"
            href="https://app.talepnet.com/pages/console"
            data-label="Site header link"
            ng-if="user"
          >
            <FormattedMessage id="LANDING.GOTO_CONSOLE" />
          </a> */}

          <tpnet-user>
            <div className="ogb-wrapper ogb-si">
              <a href="https://app.talepnet.com/pages/auth/login" className="button tpnet-top-button">
                <FormattedMessage id="LANDING.LOGIN" />
              </a>
            </div>
          </tpnet-user>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
