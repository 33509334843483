import React from "react"
import {
  FormattedMessage,
  injectIntl,
  navigate,
  Link,
} from "gatsby-plugin-intl"
import Language from "./language"
import "../styles/footer.scss"
import CardsLogo from "../images/logos/visa_master_logo.png"
import SslLogo from "../images/logos/ssl_logo.png"

const Footer = () => (
  <footer className="tpnetFooter">
    <nav className="tpnet-footer-linkboxes nocontent">
      <ul className="tpnet-footer-linkboxes-list">
        <li className="tpnet-footer-linkbox">
          <h5 className="tpnet-footer-linkbox-heading">
            <FormattedMessage id="LANDING.USERS" />
          </h5>
          <ul className="tpnet-footer-linkbox-list">
            <li className="tpnet-footer-linkbox-item">
              <Link
                to="/terms-service"
                className="tpnet-footer-linkbox-link gc-analytics-event"
              >
                <FormattedMessage id="LANDING.TERMS_SERVICE" />
              </Link>
            </li>
            <li className="tpnet-footer-linkbox-item">
              <Link
                to="/privacy-policy"
                className="tpnet-footer-linkbox-link gc-analytics-event"
              >
                <FormattedMessage id="LANDING.PRIVACY_POLICY" />
              </Link>
            </li>
            <li className="tpnet-footer-linkbox-item">
              <Link
                to="/cookie-policy"
                className="tpnet-footer-linkbox-link gc-analytics-event"
              >
                <FormattedMessage id="LANDING.COOKIE_POLICY" />
              </Link>
            </li>
            <li className="tpnet-footer-linkbox-item">
              <Link
                to="/documentation"
                className="tpnet-footer-linkbox-link gc-analytics-event"
              >
                <FormattedMessage id="LANDING.DOCUMENTATION" />
              </Link>
            </li>
          </ul>
        </li>
        <li className="tpnet-footer-linkbox ">
          <h5
            className="tpnet-footer-linkbox-heading"
            translate="LANDING.FOLLOW_US"
          >
            <FormattedMessage id="LANDING.FOLLOW_US" />
          </h5>
          <ul className="tpnet-footer-linkbox-list">
            <li className="tpnet-footer-linkbox-item">
              <Link
                to="/blog"
                className="tpnet-footer-linkbox-link gc-analytics-event"
              >
                Blog
              </Link>
            </li>
            <li className="tpnet-footer-linkbox-item">
              <a
                href="https://www.facebook.com/TalepNET"
                target="_blank"
                className="tpnet-footer-linkbox-link ga-social-media-button"
              >
                Facebook
              </a>
            </li>
            <li className="tpnet-footer-linkbox-item">
              <a
                href="https://twitter.com/TalepNET"
                target="_blank"
                className="tpnet-footer-linkbox-link ga-social-media-button"
              >
                Twitter
              </a>
            </li>
            <li className="tpnet-footer-linkbox-item">
              <a
                href="https://www.linkedin.com/showcase/34645457"
                target="_blank"
                className="tpnet-footer-linkbox-link ga-social-media-button"
              >
                Linkedin
              </a>
            </li>
            <li className="tpnet-footer-linkbox-item">
              <a
                href="https://www.youtube.com/channel/UCRYQkEMbRlvihqr_uUlayEA"
                target="_blank"
                className="tpnet-footer-linkbox-link ga-social-media-button"
              >
                YouTube
              </a>
            </li>
          </ul>
        </li>
        <li className="tpnet-footer-linkbox ">
          <h5 className="tpnet-footer-linkbox-heading">TalepNET</h5>
          <ul className="tpnet-footer-linkbox-list">
            <li className="tpnet-footer-linkbox-item">
              <Link
                to="/contact"
                className="tpnet-footer-linkbox-link gc-analytics-event"
              >
                <FormattedMessage id="LANDING.CONTACT" />
              </Link>
            </li>
            <li className="tpnet-footer-linkbox-item">
              <Link
                to="/support"
                className="tpnet-footer-linkbox-link gc-analytics-event"
              >
                <FormattedMessage id="LANDING.SUPPORT" />
              </Link>
            </li>
            <li className="tpnet-footer-linkbox-item">
              <Link
                to="/faq"
                className="tpnet-footer-linkbox-link gc-analytics-event"
              >
                <FormattedMessage id="LANDING.FAQ" />
              </Link>
            </li>
            <li className="tpnet-footer-linkbox-item">
              <a
                href="https://portal.talepnet.com"
                className="tpnet-footer-linkbox-link gc-analytics-event"
                translate="LANDING.PORTAL"
              >
                TalepNET Portal
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>

    <Language />

    <nav className="tpnet-footer-bottom">
      <img src={CardsLogo} className="logo-cards" alt="Visa_MasterCard Logo" />
      <img src={SslLogo} className="logo-ssl" alt="SSL Logo" />
    </nav>
  </footer>
)

export default Footer
