import React from "react"
import { slide as Menu } from "react-burger-menu"
import {
  FormattedMessage,
  injectIntl,
  navigate,
  Link,
} from "gatsby-plugin-intl"
import "../styles/sidebar.scss"
import TalepnetLogo from "../images/logos/talepnet-logo.png"

export default props => {
  return (
    // Pass on our props
    <Menu {...props}>
      <div className="logo-wrapper">
        <a
          href="#"
          onClick={e => {
            e.preventDefault()
            navigate("/")
          }}
          className="logo-link gc-analytics-event"
          data-label="Site logo"
        >
          <img src={TalepnetLogo} alt="TalepNET" className="logo" />
        </a>
      </div>

      <hr/>

      <Link to="/features" className="menu-item gc-analytics-event">
        <FormattedMessage id="LANDING.FEATURES" />
      </Link>

      <hr/>

      <Link to="/pricing" className="menu-item gc-analytics-event">
        <FormattedMessage id="LANDING.PRICING" />
      </Link>

      <hr/>

      <Link to="/support" className="menu-item gc-analytics-event">
        <FormattedMessage id="LANDING.SUPPORT" />
      </Link>

      <hr/>

      <Link to="/contact" className="menu-item gc-analytics-event">
        <FormattedMessage id="LANDING.CONTACT" />
      </Link>

      <hr/>

      <Link to="/faq" className="menu-item gc-analytics-event">
        <FormattedMessage id="LANDING.FAQ" />
      </Link>
    </Menu>
  )
}
